import React from "react"
import styled from "styled-components"
import PerformanceLogo from "../images/improvement-performance-icon.svg"

import {
    Box,
    Heading,
    Paragraph,
    Image,
    ResponsiveContext
} from "grommet"

import StyledImageWrapper from "../components/styled_image_wrapper"
import StyledImage from "../components/styled_image"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ImageWithOverlay from "../components/image_with_overlay"
import Button from "../components/linkbutton"
import { Succeed } from "../components/succeed"
import Container from "../components/container"

const CustomHeading = styled(Heading)`
    color: var(--blue);
    font-weight: bold;
`

const StyledHeader = styled(Heading)`
    position: relative;
`

const StyledIcon = styled(Image)`
    margin-left: -1.25em;
    height: 0.75em;
`

const StyledList = styled.ul`
    color: #576d7e;
    font-weight: bold;
    li {
        margin-bottom: 1em;
    }
`

const ShapoliPage = () => {
    const size = React.useContext(ResponsiveContext);

    return (
        <Layout>
            <Seo title="ShaPoLi" />
            <ImageWithOverlay backgroundSrc="/shapoli_slider.png">
                <Container pad="large">
                    <StyledHeader>
                        <Box direction="row" gap="small" align="baseline">
                            <StyledIcon src="/favicon-32x32.png" />
                            <Heading color="white" weight="normal">
                                The smarter, more accessible choice for
                                <br />
                                EEXI Compliance: EcoMATE™ ShaPoLi
                            </Heading>
                        </Box>
                    </StyledHeader>
                </Container>
            </ImageWithOverlay>

            <Container pad="large">
                <Box direction="row-responsive" gap="large">
                    <Box basis="3/4">
                        <CustomHeading level="2">
                            Our newest commitment to compliance: <br />
                            EcoMATE™ ShaPoLi feature
                        </CustomHeading>
                        <Paragraph>
                            With regards to EEXI regulations we've developed a subsequent module for our EcoMATE™ system. 
                            EcoMATE™ ShaPoLi is our latest feature designed for retrofitting and seamlessly supplementing existing control systems. 
                            This cost-effective solution meets IACS EEXI guidelines and provides several essential benefits.
                        </Paragraph>
                        <Button style={{ width: "260px" }} to="/contact_us">
                            Get in touch with us today
                        </Button>
                    </Box>
                    <Box basis="1/2" justify="center">
                        <StyledImageWrapper pageSize={size}>
                            <StyledImage
                                fill
                                src="/em-shapoli-screens.png"
                            />
                        </StyledImageWrapper>
                    </Box>
                </Box>
            </Container>
            <hr />
            <Container pad="large">
                <Box direction="row-responsive" gap="medium" align="center">
                    <Box basis="3/4">
                        <Paragraph>
                            <b>
                                The maritime industry faces strict IMO regulations, 
                                including the CII and EEXI, to enhance sustainability. MARPOL Annex VI, MEPC.335(76), 
                                introduces Shaft Power Limitation (ShaPoLi) as a key measure to meet EEXI standards efficiently
                            </b>
                        </Paragraph>
                        <Paragraph>
                            The EEXI mandate requires vessels over 400 gross tonnage to calculate their Efﬁciency Existing Ship Index (EEXI), 
                            measuring energy efﬁciency against benchmarks by ship type and meeting speciﬁc values tied to the Energy Efﬁciency Design Index (EEDI) baseline.
                        </Paragraph>
                        <Paragraph>
                            To comply, vessels often choose between Engine Power Limitation (EPL) and 
                            Shaft Power Limitation (ShaPoLi). While EPL mechanically limits engine power, 
                            EcoMATE ShaPoLi monitors the power transmitted to the propeller, ensuring efficient propulsion while allowing the engine to operate without restriction.
                        </Paragraph>
                        <Paragraph>
                            EcoMATE ShaPoLi is specifically designed as a cost-effective solution for retrofitting vessels, 
                            making it an ideal way to supplement onboard control systems that may lack compliance with IACS EEXI implementation guidelines. 
                            Our tamper-proof solution functions as a certified control unit for calculations, 
                            allowing operators to monitor and manage the power transmitted from the shaft to the propeller while receiving alerts on the bridge if limits are exceeded. 
                            Additionally, it automatically Automatically records exceedance events and simplifies the input of essential reporting parameters, 
                            such as reasons for exceeding limits, ensuring compliance with reporting requirements. The system also tracks required report submissions, 
                            streamlining administrative tasks, and reducing the risk of penalties.
                        </Paragraph>
                    </Box>
                    <Box basis="1/2">
                        <Box direction="column" gap="medium" pad={{vertical: "medium"}}  background="#d8e5ef" align="start" round="small">
                            <Box direction="row" justify="between" width="full" align="center" pad={{top: "small"}}>
                                <Box background="transparent" height="0px" pad={{vertical: "small", horizontal: "medium"}} style={{borderRight:"30px solid transparent", borderTop:"55px solid #f15a29"}}>
                                    <span style={{ color: "white", marginTop: "-52px", fontWeight: "700", fontSize: "25px" }}>Benefits</span>
                                </Box>
                                <Box width="70px" height="70px" margin={{ right: "medium"}}>
                                    <PerformanceLogo />
                                </Box>
                            </Box>
                            <Box pad={{horizontal: "medium"}}>
                                <StyledList>
                                    <li>Standalone or integrated module within EcoMATE</li>
                                    <li>Cost-effective retrofit solution</li>
                                    <li>Ideal for control systems, compliant with IACS EEXI guidelines</li>
                                    <li>Monitors shaft power to the propeller</li>
                                    <li>Tamper proof solution</li>
                                    <li>Bridge alarms for limit exceedance</li>
                                    <li>Automated reporting</li>
                                    <li>Key measure to meet EEXI standards</li>
                                </StyledList>
                            </Box>                  
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Succeed>
                <Heading level="3" style={{ fontWeight: "bold" }}>
                The solution for compliancy and combating climate change
                </Heading>
                <Paragraph>
                    Shipowners, operators, managers, and crew members must act to reduce emissions and prepare for compliance in a timely and cost effective manner.
                    There are numerous ways for a ship to improve its energy efficiency and reduce its carbon footprint.
                    Energy efficient instruments, systems, and technologies are required to qualify for higher ratings.
                </Paragraph>
                <Paragraph>
                    Proactively improve CII rankings, fleet performance, bunkering, and reporting while lowering costs with EcoMATE™, 
                    a complete vessel performance monitoring system and fleet operation optimisation software. 
                    A combination of our instruments, such as OPTIMASS Coriolis mass flowmeters, 
                    and the EcoMATE™ system is the ideal tool for achieving the highest ratings and implementing improvement plans.
                </Paragraph>
                <Paragraph>
                    Achieve the ability to combine profitability and performance by utilising our comprehensive system and instruments.
                    Gain a positive change for your company and for the environment.
                </Paragraph>
            </Succeed>
        </Layout>
    );
}

export default ShapoliPage;
